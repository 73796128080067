<template>
    <div class="sh_comment_main" >

        <!--二级评论框-->
      <van-overlay :show="showInp" @click="reload()" >
        <div class="wrapperInp" >
          <div class="wrapperInpBloack" ref="textareaRef" @click.stop>
            <div class="wrapperInp_head">
              <span>我要评论</span>
              <van-icon name="arrow-down" @click="showInp = false" />
            </div>
            <div class="wrapperInp_text">
              <!--<textarea data-index="1" ref='focusTextarea' rows="3" cols="20" v-model="content" placeholder="请输入留言">-->

              <!--</textarea>-->
              <van-field
                refs='commentCearch'
                v-model="content"
                rows="1"
                :autofocus="focusState"
                ref="commentCearch"
                type="textarea"
                maxlength="140"
                placeholder="请输入留言"
                show-word-limit
              />
            </div>
            <div class="wrapperInpBtn" @click="addOther()">
              发表
            </div>
          </div>
        </div>
      </van-overlay>












      <!-- 头部 -->
        <div class="sh_comment_header" v-show="comments&&comments.length" ref='sh_comment_header'>
            <div class="comment_title">网友评价</div>
            <div class="comment_num">({{formatNum(total)}})人评论</div>
            <!-- <van-rate :value="star" size="12px" color="#FF577B" void-color="#FF577B" class="comment_star"/> -->
        </div>



        <!-- 评论内容项 -->
        <div class="comment_item" v-show="comments&&comments.length" v-for="(item, index) in comments" :key="index">
            <!-- 头像、昵称、评分 -->
            <div class="comment_item_header">
                <van-image  width="25px"
                            height="25px"  round :src="item.headPortrait">
                    <template slot="error">头像</template>
                </van-image>
                <div class="comment_user_nickname">{{item.nickname}}</div>
                <div class="comment_user_like" :class="item.isPraise == 1 ? 'icon_liked' : 'icon_like'" @click="praise(item.criticismId, commentBizType, item.isPraise)">({{formatNum(item.praiseCount)}})</div>
            </div>
            <!-- 具体内容 -->
            <div class="comment_item_info" @click="otherState(item)">
                <span>{{item.content}}</span>
                <span class="comment_time">{{formatTime(item.criticismDate)}}</span>
            </div>
            <!-- 子评论 -->
            <div class="comment_childs" v-if="item.child && item.child.length > 0">
                <div class="comment_item" v-for="(sub, i) in item.child" :key="i">
                    <div class="comment_item_header">
                        <!--<van-image class="comment_user_avatar" round :src="sub.headPortrait">-->
                            <!--<template slot="error">头像</template>-->
                        <!--</van-image>-->
                        <van-image  width="25px"
                                    height="25px"  round :src="sub.headPortrait">
                          <template slot="error">头像</template>
                        </van-image>
                        <div class="comment_user_nickname">{{sub.nickname}}</div>
                        <div class="comment_user_like" :class="sub.isPraise == 1 ? 'icon_liked' : 'icon_like'"  @click="praise(sub.criticismId, commentBizType, sub.isPraise)">({{formatNum(sub.praiseCount)}})</div>
                    </div>
                    <div class="comment_item_info">
                        <span>{{sub.content}}</span>
                        <span class="comment_time">{{formatTime(sub.criticismDate)}}</span>
                    </div>
                </div>
                <!-- <div class="comment_more">展开5条回复</div> -->
            </div>
        </div>
        <!-- 为防止底部输入框挡住内容，放置此占位 -->
        <div class="blank_div"></div>
        <!-- 输入框 -->

        <div class="comment_form" v-if="allowInput">
            <form action="/">
              <van-search data-index="2" v-if="!showInp"  @focus="otherState()" ref="commentCearch"  left-icon="edit" class="comment_input" v-model="content" placeholder="我也说两句"  />
            </form>
            <div class="comment_opt_item" :class="isPraiseC ? 'icon_liked' : 'icon_like'"  @click="praise(bizId, bizType, isPraiseC)">({{formatNum(praiseNumC)}})</div>
            <div class="comment_opt_item" :class="isCollectC ? 'icon_collected' : 'icon_collect'" @click="collect()">({{formatNum(collectNumC)}})</div>
            <div class="comment_opt_item like icon_comment" @click="scrollComment()">({{formatNum(total)}})</div>
        </div>
        <div class="td_inp" v-else>
          <van-search data-index="2" v-if="!showInp"  @focus="otherState()" ref="commentCearch"  left-icon="edit" class="comment_input" v-model="content" placeholder="我也说两句"  />

          <div class="opt_btn  td_inp_experience" v-if="interlinkage" @click="openUrl()">立即体验</div>
          <div class="opt_btn td_inp_bor"  v-else>暂未开放手机体验</div>
        </div>
    </div>
</template>

<script src='./comment.js'></script>
<style src='./comment.css' scoped></style>
