import{ Overlay, Icon, Field, Rate, Image, Toast} from 'vant'

export default{
  components:{
    'van-overlay': Overlay,
    'van-icon': Icon,
    'van-field': Field,
    'van-rate': Rate,
    'van-image': Image,
  },
  data() {
    return {
      apis:{
        list:'/userCriticism/slicedQueryUserCriticism',//查询点赞列表
        add:'/userCriticism/insertUserCriticism',//添加评论
        addPraise:'/userPraise/addPraise',//点赞
        cancelPraise:'/userPraise/cancelPraise',//取消点赞
        addCollect:'/userCollect/addCollect',//取消点赞
        cancelCollect:'/userCollect/cancelMyCollect',//取消点赞
      },
      token:this.$global.token,//当前登录用户令牌
      commentBizType: 2,//针对评论的业务类型，固定值2，随后台维护改变
      params:{
        pageIndex:1,//当前页码
        pageSize:1000,
        sortName:'praise_count',//排序字段，按点赞数排序
        sortOrder:'desc',//排序方式，倒序
        token:this.token,//用户令牌
      },
      paramsOther:{

      },
      showInp:false,
      state:true,
      content:'',//评论内容
      total: 0,//'评论总数',
      comments:[],//评论列表
    }
  },
  props:{
    bizType:null,//业务类型
    bizId:null,//业务ID
    teliphone:'',
    isPraise:{//我是否已经点赞
      type:Number,
      default:0
    },
    isCollect:{//我是否已经收藏
      type:Number,
      default:0
    },
    collectNum:{//收藏数量
      type:Number,
      default:0
    },
    praiseNum:{//点赞数量
      type:Number,
      default:0
    },
    star:{//评分
      type:Number,
      default:0
    },
    isConment:{//评分
      type:Boolean,
      default:false
    }
  },
  watch: {
    bizType:function(){
      this.loadComment();
    },
    bizId:function(){
      this.loadComment();
    },
    isConment:function(){
      // this.loadComment();
    }

  },
  mounted() {
    this.loadComment();
  },
  methods: {
    comment (){
      this.$emit('commetFunc',true);
    },
    callPhone (phoneNumber) {
      window.location.href = 'tel://' + this.teliphone
    },

    /**
     * 格式化数字，数字为空时显示0
     * 如果数字大于999，则显示999+
     */
    formatNum(v){
      return (v || v > 0) ? (v > 999 ? '999+' : v) : 0;
    },
    /**
     * 格式化时间，返回格式（月-日）
     * @param {时间} t
     */


    formatTime(t){
      return t && t.length >= 10 ? t.slice(5, 10) : '';
    },
    praise(bizId, bizType, isPraise){//点赞按钮响应事件
      isPraise === 1 ? this.cancelPraise(bizId, bizType) : this.addPraise(bizId, bizType);
    },
    addPraise(bizId, bizType){//添加点赞
      const me = this;
      me.$post({
        url: me.apis.addPraise,
        params:{
          token: me.$global.token,
          bizType: bizType,
          bizId: bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        bizType == me.commentBizType ? me.loadComment() : me.$emit('praise', this, 'add');

      })
    },
    cancelPraise(bizId, bizType){//取消点赞
      const me = this;
      me.$post({
        url: me.apis.cancelPraise,
        params:{
          token: me.$global.token,
          bizType: bizType,
          bizId: bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        bizType == me.commentBizType ? me.loadComment() : me.$emit('praise', this, 'cancel');
      })
    },
    collect(bizId, bizType, isCollect){//收藏事件响应事件
      isCollect === 1 ? this.cancelCollect(bizId, bizType) : this.addCollect(bizId, bizType);
    },
    addCollect(bizId, bizType){//添加收藏
      const me = this;
      console.log(me.contentId);
      me.$post({
        url: me.apis.addCollect,
        params:{
          token:me.$global.token,
          bizType: me.bizType,
          bizId: me.bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        me.$emit('collect', this, 'add');
      })
    },
    cancelCollect(){//取消收藏
      const me = this;
      me.$post({
        url: me.apis.cancelCollect,
        params:{
          token: me.$global.token,
          bizType: me.bizType,
          bizId: me.bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        me.$emit('collect', this, 'cancel');
      });
    },
    loadComment(){//获取评论列表
      const me = this;
      me.params.bizId = me.bizId;
      me.params.bizType = me.bizType;
      me.params.token = me.$global.token;
      me.$post({
        url:me.apis.list,
        params: me.params
      }).then(rsp => {
        me.total = rsp.data.count;
        me.comments = rsp.data.data;
      });
    },
    addState(v,obj){
      if(v==1){
        this.showInp = true
        this.state= true


      }else if(v==2){
        this.showInp = true
        this.state= false
        this.paramsOther.parentId = obj.criticismId;
        this.paramsOther.bizType = obj.bizType;
      }


    },

    addComment(){//添加评论
      const me = this;
      if(!me.content){
        Toast('请输入评论内容');
        return;
      }
      let arr = {}
      if(me.state){
        arr= {
          content: me.content,
          bizId: me.bizId,
          bizType: me.bizType,
          parentId: 0
        }
      }else {
        arr= {
          content: me.content,
          bizId: me.bizId,
          bizType: this.paramsOther.bizType,
          parentId:this.paramsOther.parentId
        }
      }

      me.$post({
        url:me.apis.add,
        params: {
          token: me.$global.token,
          data: arr
        }
      }).then(rsp => {
        me.loadComment();
        this.showInp = false;
        me.content = ''
      });
    },
  },
}
