module.exports = {
  // 测试环境
  // 领创科技
  // baseUrl: "https://zylswxapics.lintrondata.com/",
  // appId: 'wx91502938dcafe62d',
  // visitUrl: 'https://zylswxcs.lintrondata.com/zyls/',
  // baseMallUrl: 'https://zylswxcs.lintrondata.com/shop/social_user_login/auto_sign_in?token=',

  //正式 识途
  // baseUrl: "http://intf.63517.net/", //正式环境(需要确定是否是https)
  baseUrl: "http://m.63517.net/zyls-api/", //正式环境(需要确定是否是https)
  baseMallUrl: 'http://m.63517.net/shop/social_user_login/auto_sign_in?token=',
  appId: 'wxffa72b5375f8d3cd',
  // visitUrl: 'http://m.ciotour.com/zyls-wx/',
  visitUrl: 'http://m.63517.net/zyls-wx/',
  token: '', //用户token


  //领创数智科技  票务系统下测试
  // baseUrl: "http://smgpt.lintrondata.com/zyls-api/",
  // visitUrl: 'http://smgpt.lintrondata.com/zyls-wx/',
  // appId: 'wx67d8303e2c35adfc',
  // baseMallUrl: 'https://zylswxcs.lintrondata.com/shop/social_user_login/auto_sign_in?token=',
 //记得改 vue.config.js 的 publicPath


  // token: 'd90587b5-2e06-4c7e-8f87-4ad1bf42ab6f', //用户token



  // 服务器url
  // baseUrl: "http://10.8.117.44:9100/", //童工
  // baseUrl: "http://10.8.205.47:9100/", //杨磊
  // baseUrl: "http://10.8.205.53:9100/", //陈力

  /*1.测试打包的时候需要注释掉
  2.route.js  搜索注释
  */
  // token: "72eebe19-b507-4d42-8a15-c241abc3a080",

  // appPayKey:'NG8XQB9QFN8TZ0F7E5G8TRKMCMX6CZPU',//测试
  appPayKey: 'ZyLs54577sdsajcnjLsdIioiOiPQWWDS',// 正式

  // loactions: '', //城市gps
  modelState: true, //true是安卓false是ios
  cityId: '', //城市id
  chartColor: ['#00897B', '#2196f3', '#3f51b5', '#7b4ffc', '#ff54a8', '#e53935',
    '#8e24aa', '#009688', '#22bb87', '#8bc34a', '#cddc39', '#ffeb3b',
    '#ffc107', '#ff9800', '#ff5722', '#651fff', '#7c4dff', '#aa00ff',
    '#d81b60', '#512da8', '#00ac95', '#00c853', '#009688', '#0274a9',
    '#34be7c', '#43a047'
  ], //Echart图形自定义颜色
  projectYear: 2021, //项目库的年份，
  isAddPerson: false,
  audio: null,//音频
  shareTitle: '智游乐山',
  shareIntro: '智游乐山',
  shareImage: 'https://zyls-test2.oss-cn-chengdu.aliyuncs.com/峨眉山266d198a8-b1d6-4c96-81dd-afd28af1fb45.jpg',
}
