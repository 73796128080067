import axios from 'axios'; // 引入axios
import global from '../util/global'; // 引入配置文件
import router from '../router/index';
import { Toast } from 'vant';
import md5 from 'js-md5';
import store from '../store/index.js';
axios.defaults.timeout = global.serverTimeout; // 配置请求超时时间
axios.defaults.baseURL = global.baseUrl;
axios.defaults.headers = []
// request模型
var requestHandler = {
  url: null,//请求地址
  params: {},//请求参数
  type: 'WEB',//请求类型，WEB网页端请求，APP移动端的请求
}

/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function get( requestHandler) {
  return new Promise((resolve, reject) => {
    if(!requestHandler.closeToast){
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: ''
      });
    }
    axios.get(requestHandler.url, {
      params: requestHandler.params
    }).then(response => {
      Toast.clear();
      var res = response.data;
      switch (res.status) {
        case "1": //正确
          resolve(res.data ? res.data : res);
          break;
        default:
          Toast(res.message);
          break;
      }
    })
      .catch(err => {
        Toast.clear();
        reject(err)
      })
  })
}

/** 是否存在弹窗 */
let hasMsgBox = false;


/**
 *随机生成字符串
 */

function getRadomNum(capacity) {
  let chars = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
  let res = "";
  for (let i = 0; i < capacity; i++) {
    var id = Math.ceil(Math.random() * chars.length);
    res += chars[id];
  }
  return res;
}




/**
 * 封装Post方法
 * @param url
 * @param data
 * @returns {Promise}
 */

Toast.setDefaultOptions({ duration: 2000 });

// 将所有 loading Toast 设置为背景不可点击 (2.2.10 版本开始支持)
Toast.setDefaultOptions('loading', { forbidClick: true });

export function post(cfg) {

  const token = global.token

  // const token = "43d44c23-0b13-4d12-adfc-32baf2374193";//模拟token
  // const token = window.android.getToekn();//模拟token
  // if(!token){
  //   Toast('请先登录');
  //   return;
  // }
  const appSecret = '2RGOGNFBFTSR7EXE';//固定端的key
  const client = 'stls_app_key';//来源端
  const time = Date.parse(new Date());//时间搓
  const nonceStr = getRadomNum(10);//随机字符串
  const key = md5(`${appSecret}${time}${client}${nonceStr}`);//key


  return new Promise((resolve, reject) => {
    if (!cfg || !cfg.url) {
      Toast('无请求参数');
      return;
    }

    if(!cfg.hideLoading){
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: ''
      });
    }
    axios({
      method: 'post',
      url: cfg.url,
      data: cfg.params,
      timeout: 40000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'client': client,
        'time': time,
        'key': key,
        "nonceStr": nonceStr,
        "token":token
      }
    }).then(function (response) {
      var res = response.data;
      Toast.clear();
      if (res.status == 1 || res.code == 200) { //成功
        resolve(res);
      } else { //出错

        if (res.code == "U1001") {
          Toast('请先登录');
          if(global.modelState){
            global.token = window.android.getToken()?window.android.getToken():null;

          }else {
            function setupWebViewJavascriptBridge(callback) {//ios联通h5的方法
              if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
              if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
              window.WVJBCallbacks = [callback];
              var WVJBIframe = document.createElement('iframe');
              WVJBIframe.style.display = 'none';
              WVJBIframe.src = 'https://__bridge_loaded__';
              document.documentElement.appendChild(WVJBIframe);
              setTimeout(function() { document.documentElement.removeChild(WVJBIframe) }, 0)
            }
            setupWebViewJavascriptBridge(function(bridge) {
              bridge.callHandler('getToken', null,function(data) {
                global.token = data?data:null;
              })
            })
          }
        }
        if(res.code == "U5001"){
          Toast('非法的评论');
        }else{
          reject(res);
        }
      }
    }).catch(err => {
      Toast.clear();
      reject(err)
    })
  })
}



export function post2(cfg) {
  const token = global.token
  const appSecret = '2RGOGNFBFTSR7EXE';//固定端的key
  const client = 'stls_app_key';//来源端
  const time = Date.parse(new Date());//时间搓
  const nonceStr = getRadomNum(10);//随机字符串
  const key = md5(`${appSecret}${time}${client}${nonceStr}`);//key

  sessionStorage.setItem('timestamp',time);
  return new Promise((resolve, reject) => {

    // Toast.loading({
    //   duration: 0, // 持续展示 toast
    //   forbidClick: true,
    //   message: '加载中'
    // });
    axios({
      method: 'post',
      url: cfg.url,
      data: cfg.params,
      timeout: 40000,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        'client': client,
        'time': time,
        'key': key,
        "nonceStr": nonceStr,
        "token":token
      }
    }).then(function (response) {

      var res = response.data;

      if (res.status == 1) { //成功
        resolve(res);
      } else { //出错

        if (res.code == "U1001") {
          Toast('请重新登录');
        }
        if (res.code === "999") {
          sessionStorage.setItem("token", "");
          store.dispatch("logout").then(() => {
            router.replace({ path: "/home", params: { id: 1 } });
          });
        }
        reject(res)
      }
    }).catch(err => {
      console.log(err);
      reject(err)
    })
  })
}




export function get2(requestHandler) {
  return new Promise((resolve, reject) => {

    axios.get(requestHandler.url, {
      params: requestHandler.params
    }).then(response => {
      var res = response.data;
      switch (res.status) {
        case "1": //正确
          resolve(res.data);
          break;
        default:
          Toast(res.message);

          break;
      }
    })
      .catch(err => {
        reject(err)
      })
  })
}


export function get3(cfg) {
  const token = global.token
  return new Promise((resolve, reject) => {
    axios({
      method: 'get',
      url: cfg.url,
      data: cfg.params,
      timeout: 40000,
      headers: {
        "token":token
      }
    }).then(function (response) {
      var res = response.data;
      if (res.status == 1) { //成功
        resolve(res);
      } else { //出错
        if (res.code == "U1001") {
          Toast('请重新登录');
        }
        if (res.code === "999") {
          sessionStorage.setItem("token", "");
          store.dispatch("logout").then(() => {
            router.replace({ path: "/home", params: { id: 1 } });
          });
        }
        reject(res)
      }
    }).catch(err => {
      console.log(err);
      reject(err)
    })
  })
}
