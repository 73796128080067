import{ Overlay, Icon, Field, Image, Search, Toast} from 'vant'

export default{
  components:{
    'van-overlay': Overlay,
    'van-icon': Icon,
    'van-field': Field,
    'van-image': Image,
    'van-search': Search,
  },
  data() {
    return {
      apis:{
        list:'/userCriticism/slicedQueryUserCriticism',//查询点赞列表
        add:'/userCriticism/insertUserCriticism',//添加评论
        addPraise:'/userPraise/addPraise',//点赞
        cancelPraise:'/userPraise/cancelPraise',//取消点赞
        addCollect:'/userCollect/addCollect',//取消点赞
        cancelCollect:'/userCollect/cancelMyCollect',//取消点赞
      },
      commentBizType: 2,//针对评论的业务类型，固定值2，随后台维护改变
      params:{
        pageIndex:1,//当前页码
        pageSize:1000,
        sortName:'praise_count',//排序字段，按点赞数排序
        sortOrder:'desc',//排序方式，倒序
      },
      paramsOther:{
        bizType:3,

        bizId:this.bizId,
        content:''
      },
      focusState:true,
      state:false,
      content:'',//评论内容
      total: 0,//'评论总数',
      showInp:false,
      comments:[],//评论列表
      isPraiseC:0,
      isCollectC:0,
      collectNumC:0,
      praiseNumC:0,
    }
  },
  props:{
    bizType:null,//业务类型
    bizId:null,//业务ID
    isPraise:{//我是否已经点赞
      type:Number,
      default:0
    },
    interlinkage:null,
    isCollect:{//我是否已经收藏
      type:Number,
      default:0
    },
    collectNum:{//收藏数量
      type:Number,
      default:0
    },
    praiseNum:{//点赞数量
      type:Number,
      default:0
    },
    star:{//评分
      type:Number,
      default:0
    },
    allowInput:{//是否需要显示输入框
      type:Boolean,
      default:true
    }
  },
  watch: {
    bizType:function(){
      this.loadComment();
    },
    bizId:function(){
      this.loadComment();
    }
  },
  mounted() {
    this.isCollectC = this.isCollect;
    this.collectNumC = this.collectNum;
    this.isPraiseC = this.isPraise;
    this.praiseNumC = this.praiseNum;
    setTimeout(()=>{
      this.loadComment();
    },1000)
  },
  methods: {
    reload(){
      this.showInp = false;
    },
    openUrl(){//打开某个链接
      this.$router.push({
        path:'/outWindow',
        query:{
          interlinkage:encodeURI(this.interlinkage)
        }
      })
    },
    /**
     * 格式化数字，数字为空时显示0
     * 如果数字大于999，则显示999+
     */
    formatNum(v){
      return (v || v > 0) ? (v > 999 ? '999+' : v) : 0;
    },
    /**
     * 格式化时间，返回格式（月-日）
     * @param {时间} t
     */
    formatTime(t){
      return t && t.length >= 10 ? t.slice(5, 10) : '';
    },
    praise(bizId, bizType, isPraise){//点赞按钮响应事件
      isPraise == 1 ? this.cancelPraise(bizId, bizType) : this.addPraise(bizId, bizType);
    },
    addPraise(bizId, bizType){//添加点赞
      const me = this;
      me.$post({
        url: me.apis.addPraise,
        params:{
          token: me.$global.token,
          bizType: bizType,
          bizId: bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        //   me.$emit('praise', this, 'add');
        //如果是针对评论点赞或取消点赞，则只刷新评论
        // bizType == me.commentBizType ? me.loadComment() : me.$emit('praise', this, 'add');
        bizType == me.commentBizType ? me.loadComment() :  (me.isPraiseC = 1,me.praiseNumC++) ;

      })
    },
    cancelPraise(bizId, bizType){//取消点赞
      const me = this;
      me.$post({
        url: me.apis.cancelPraise,
        params:{
          token: me.$global.token,
          bizType: bizType,
          bizId: bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        bizType == me.commentBizType ? me.loadComment() : (me.isPraiseC = 0, me.praiseNumC--);
      })
    },
    collect(){//收藏事件响应事件
      this.isCollectC == 1 ? this.cancelCollect() : this.addCollect();
    },
    addCollect(){//添加收藏
      const me = this;
      me.$post({
        url: me.apis.addCollect,
        params:{
          token:me.$global.token,
          bizType: me.bizType,
          bizId: me.bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        me.isCollectC = 1;
        me.collectNumC++;
        // me.$emit('collect', this, 'add');
      })
    },
    cancelCollect(){//取消收藏
      const me = this;
      me.$post({
        url: me.apis.cancelCollect,
        params:{
          token: me.$global.token,
          bizType: me.bizType,
          bizId: me.bizId
        }
      }).then(rsp => {
        Toast('操作成功');
        me.isCollectC = 0;
        me.collectNumC--;
        console.log(me.isCollectC,me.collectNumC)
        // me.$emit('collect', this, 'cancel');
      });
    },
    loadComment(){//获取评论列表
      const me = this;
      me.params.bizId = me.bizId;
      me.params.bizType = me.bizType;
      me.params.token = me.$global.token;
      me.$post({
        url:me.apis.list,
        params: me.params,
        hideLoading:true,
      }).then(rsp => {
        me.total = rsp.data.count;
        me.comments = rsp.data.data;
      });
    },
    addOther(){//添加评论
      const me = this;
      if(!me.content){
        Toast('请输入评论内容');
        return;
      }
      let arr = {}
      if(me.state){
        arr= {
          content: me.content,
          bizId: me.bizId,
          bizType: me.bizType,
          parentId: 0
        }
      }else {
        arr= {
          content: me.content,
          bizId: me.bizId,
          bizType: this.paramsOther.bizType,
          parentId:this.paramsOther.parentId
        }
      }
      me.$post({
        url:me.apis.add,
        params: {
          token: me.$global.token,
          // token:'8c811210-bc98-4be8-8ccc-b138229be72a',
          data: arr
        }
      }).then(rsp => {
        me.loadComment();
        me.showInp = false
        me.content = ''
      });
    },
    otherState(obj){
      if(!this.$global.token){
        this.$toast('请先登录');
        return
      }
      if(obj){
        this.paramsOther.parentId = this.obj.criticismId;
        this.paramsOther.bizType = this.obj.bizType;
        this.state= false
      }else{
        this.state= true
      }
      this.showInp = true
    },
    scrollComment(){//滚动到评论页
      const me = this, dom = me.$refs['sh_comment_header'];
      console.log(111);
      if(dom.offsetTop){
        document.body.scrollTop = dom.offsetTop
        document.documentElement.scrollTop = dom.offsetTop
      }
    }
  },
}
