<template>
  <div>
    <router-view></router-view>
  </div>
</template>
<script>
  export default {
    name: "App",
    data() {
      return {
        transitionName: "slide-right" //初始过渡动画方向
      };
    },
    computed: {},
    created() {
      const me = this;
      let u = navigator.userAgent;
      if (u.indexOf("Android") > -1 || u.indexOf("Linux") > -1) {
        //安卓token方法
        me.$global.token = window.android.getToken()
          ? window.android.getToken()
          : null;
        me.$global.modelState = true;
      } else if (u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1 || u.indexOf('iPad') > -1) {
        //ios的token方法
        me.$global.modelState = false;
        me.$iosMethod(function(bridge) {
          bridge.callHandler("getToken", null, function(data) {
            me.$global.token = data ? data : null;
          });
        });
      }
    },
    mounted() {},
    methods: {},
    watch: {
    },
    destroyed() {}
  };
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
