import api from '../api/index'
import {post } from '../util/axios'; // 引入axios
import global from '../util/global';
function getShareInfo(tit,fxUrl,pic,desc){//如果分享的内容会根据情况变化，那么这里可以传入分享标题及url
 let shareUrl = window.location.href.split('#')[0]
  const wx = require('weixin-js-sdk');
  post({
    url:global.baseUrl+ 'getWechatSDKSignature',
    params: {
      shareUrl:shareUrl
    }}).then(res=>{
    wx.config({
      debug: false,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
      appId: global.appId,         // 必填，公众号的唯一标识，填自己的！
      timestamp:res.data.timestamp, // 必填，生成签名的时间戳，刚才接口拿到的数据
      nonceStr: res.data.nonceStr,  // 必填，生成签名的随机串
      signature: res.data.signature,// 必填，签名，见附录1
      jsApiList: [
        'updateTimelineShareData',
        'updateAppMessageShareData'
      ]
    });
    wx.ready(function () {
      wx.updateTimelineShareData({//updateTimelineShareData
        title: tit,   // 分享时的标题
        link: fxUrl,     // 分享时的链接
        imgUrl:pic,    // 分享时的图标
        success: function () {
          console.log("分享成功");
        },
        cancel: function () {
          console.log("取消分享");
        }
      });
      wx.updateAppMessageShareData({
        desc: desc,
        title: tit,   // 分享时的标题
        link: fxUrl,     // 分享时的链接
        imgUrl:pic,    // 分享时的图标
        success: function () {
          console.log("分享成功");
        },
        cancel: function () {
          console.log("取消分享");
        }
      });
    })
    wx.error(function(res){
      console.log(res);

    });
  })



}

export default  getShareInfo
