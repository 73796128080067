import { createRouter, createWebHistory, createWebHashHistory, } from 'vue-router'
const global = require('../util/global');
import api from '../api/index'
import {
  post
} from '../util/axios'; // 引入封装的网络请求
const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: '首页' },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/my',
    name: 'My',
    meta: { title: '我的' },
    component: () => import('../views/My.vue')
  },
  {
    path: '/live',
    name: 'live',
    meta: { title: '' },
    component: () => import('../views/home/live/live.vue')
  },
  {
    path: '/hotels',
    name: 'hotels',
    component: () => import('../views/hotel/index.vue'), // 酒店首页
    meta: {
      auth: false,
      title: '酒店',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelList',
    name: 'hotelList',
    component: () => import('../views/hotel/hotelList.vue'), // 酒店列表
    meta: {
      auth: false,
      title: '酒店列表',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelOrderDetail',
    name: 'hotelOrderDetail',
    component: () => import('../views/hotel/order/hotelOrderDetail.vue'), // 酒店订单详情
    meta: {
      auth: false,
      title: '订单详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/expenseDetails',
    name: 'expenseDetails',
    component: () => import('../views/hotel/order/expenseDetails.vue'), // 费用明细
    meta: {
      auth: false,
      title: '费用明细',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/refund',
    name: 'refund',
    component: () => import('../views/home/order/refund.vue'), // 费用明细
    meta: {
      auth: false,
      title: '退款详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/refundProgress',
    name: 'refundProgress',
    component: () => import('../views/hotel/order/refundProgress.vue'), // 退款进度
    meta: {
      auth: false,
      title: '退款进度',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelDetail',
    name: 'hotelDetail',
    component: () => import('../views/hotel/hotelDetail.vue'), // 酒店首页
    meta: {
      auth: false,
      title: '酒店详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/fillInformation',
    name: 'fillInformation',
    component: () => import('../views/hotel/fillInformation.vue'), // 填写入住信息
    meta: {
      auth: false,
      title: '填写入住信息',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelPay',
    name: 'hotelPay',
    component: () => import('../views/hotel/order/pay.vue'), // 酒店支付
    meta: {
      auth: false,
      title: '酒店支付',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelPaySuccess',
    name: 'hotelPaySuccess',
    component: () => import('../views/hotel/order/paySuccess.vue'), // 酒店支付成功
    meta: {
      auth: false,
      title: '支付成功',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelInner',
    name: 'hotelInner',
    component: () => import('../views/hotel/hotelInner.vue'), // 酒店内页
    meta: {
      auth: false,
      title: '酒店内页',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/album',
    name: 'album',
    component: () => import('../views/home/album/album.vue'), // 酒店相册
    meta: {
      auth: false,
      title: '景区相册',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelAlbum',
    name: 'hotelAlbum',
    component: () => import('../views/hotel/album.vue'), // 酒店相册
    meta: {
      auth: false,
      title: '酒店相册',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/guide',
    name: 'Guide',
    meta: { title: '导游导览' },
    component: () => import('../views/Guide.vue')
  },
  {
    path: '/phone',
    name: 'Phone',
    meta: { title: '绑定手机' },
    component: () => import('../views/Phone.vue')
  },
  {
    path: '/edit',
    name: 'Edit',
    meta: { title: '完善资料' },
    component: () => import('../views/Edit.vue')
  },
  {
    path: '/order',
    name: 'Order',
    meta: { title: '我的订单' },
    component: () => import('../views/Order.vue')
  },
  {
    path: '/service',
    name: 'Service',
    meta: { title: '客服咨询' },
    component: () => import('../views/Service.vue')
  },
  {
    path: '/message',
    name: 'Message',
    meta: { title: '我的消息' },
    component: () => import('../views/Message.vue')
  },
  {
    path: '/integral',
    name: 'Integral',
    meta: { title: '我的积分' },
    component: () => import('../views/Integral.vue')
  },
  {
    path: '/moneyDetail',
    name: 'moneyDetail',
    meta: { title: '费用明细' },
    component: () => import('../views/home/order/moneyDetail.vue'),
  },
  {
    path: '/messageDetail',
    name: 'MessageDetail',
    meta: { title: '消息详情' },
    component: () => import('../views/MessageDetail.vue') // 消息详情页
  },
  {
    path: '/set',
    name: 'Set',
    meta: { title: '系统设置' },
    component: () => import('../views/Set.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    meta: { title: '意见反馈' },
    component: () => import('../views/Feedback.vue')
  },
  {
    path: '/comment',
    name: 'Comment',
    meta: { title: '我的评论' },
    component: () => import('../views/Comment.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    meta: { title: '我的收藏' },
    component: () => import('../views/Collection.vue')
  },
  {
    path: '/policy',
    name: 'Policy',
    meta: { title: '' },
    component: () => import('../views/Policy.vue') // 服务协议隐藏条款展示页
  },
  {
    path: '/weather',
    name: 'weather',
    component: () => import('../views/weather.vue'),// 天气
    meta: {
      auth: false,
      title: '当前城市',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/search',
    name: 'search',
    component: () => import('../views/home/clockIn/search/search.vue'), // 页面
    meta: {
      auth: false,
      title: '搜索',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/searchCont',
    name: 'searchCont',
    component: () => import('../views/home/clockIn/searchCont/searchCont.vue'), // 页面
    meta: {
      auth: false,
      title: '搜索-结果',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/mallDetails',
    name: 'mallDetails',
    component: () => import('../views/mall/mallDetails/mallDetails.vue'), // 页面
    meta: {
      auth: false,
      title: '商品详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/foodrank',
    name: 'foodrank',
    component: () => import('../views/home/food/foodrank/foodrank.vue'), // 页面
    meta: {
      auth: false,
      title: '美食排行',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/guideDetails',
    name: 'guideDetails',
    component: () => import('../views/guide/guideDetails/guideDetails.vue'), // 页面
    meta: {
      auth: false,
      title: '导游人物详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/recreDetails',
    name: 'recreDetails',
    component: () => import('../views/home/recreation/recreDetails/recreDetails.vue'), // 页面

    meta: {
      auth: false,
      title: '娱乐活动-详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/liveDetail/:id',
    name: 'liveDetail',
    component: () => import('../views/home/live/liveDetail/liveDetails.vue'), // 页面
    meta: {
      auth: false,
      title: '智游乐山-详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    // path: '/live5g/:id',
    path: '/live5g/:id',
    name: 'live5g',
    component: () => import('../views/home/live/live5g/live5g.vue'), // 页面
    meta: {
      auth: false,
      title: '智游乐山-5g',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/travelExpress',
    name: 'travelExpress',
    component: () => import('../views/home/travelExpress/list.vue'), // 旅游快报
    meta: {
      auth: false,
      title: '旅游快报',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/travelExpressDetail',
    name: 'travelExpressDetail',
    component: () => import('../views/home/travelExpress/detail.vue'), // 旅游快报详情页
    meta: {
      auth: false,
      title: '旅游快报详情页',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/tourist',
    name: 'tourist',
    component: () => import('../views/home/tourist/index.vue'), // 景区客流
    meta: {
      auth: false,
      title: '景区客流',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/touristRoutes',
    name: 'touristRoutes',
    component: () => import('../views/home/touristRoutes/index.vue'), // 旅游路线
    meta: {
      auth: false,
      title: '精品路线',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/lineList',
    name: 'lineList',
    component: () => import('../views/home/touristRoutes/lineList.vue'), // 最新推荐路线
    meta: {
      auth: false,
      title: '推荐路线',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/lineTwoDetails',
    name: 'lineTwoDetails',
    component: () => import('../views/home/lineTwo/lineTwoDetails/lineTwoDetails.vue'),
    meta: {
      auth: true,
      title: '精品路线2详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import('../views/home/tickets/tickets.vue'),
    meta: {
      auth: true,
      title: '景点门票',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/tiketDetails/:id',
    name: 'tiketDetails',
    component: () => import('../views/home/tickets/tiketDetails/tiketDetails.vue'),
    meta: {
      auth: false,
      title: '景点详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/buyTip',
    name: 'buyTip',
    component: () => import('../views/home/buyTip/buyTip.vue'),
    meta: {
      auth: false,
      title: '购票须知',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/notice/:id',
    name: 'notice',
    component: () => import('../views/home/tickets/notice/notice.vue'),
    meta: {
      auth: false,
      title: '购票须知',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/notice',
    name: 'notice',
    component: () => import('../views/home/notice/notice.vue'),
    meta: {
      auth: false,
      title: '重要通知',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/intro',
    name: 'intro',
    component: () => import('../views/home/intro/intro.vue'),
    meta: {
      auth: false,
      title: '景点详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    // path: '/live5g/:id',
    path: '/liveAnother/:id',
    name: 'liveAnother',
    component: () => import('../views/home/live/liveAnother/liveAnother.vue'),
    meta: {
      auth: false,
      title: '趣看不一样',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/cityCard',
    name: 'cityCard',
    component: () => import('../views/home/cityCard_new/cityCard.vue'),
    meta: {
      auth: true,
      title: '城市名片',
      keepAlive: false,
      routeState: false
    }

  },
  {
    path: '/cityDetails',
    name: 'KeepAlive',
    component: () => import('../views/home/cityCard_new/cityDetails/cityDetails.vue'),
    meta: {
      auth: true,
      title: '城市名片-详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/submitOrder',
    name: 'submitOrder',
    component: () => import('../views/home/order/submitOrder.vue'),
    // component: () => import('../views/ski/ticket/submitOrder.vue'),
    meta: {
      auth: false,
      title: '提交订单',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/submitOrderQw',
    name: 'submitOrderQw',
    // component: () => import('../views/home/order/submitOrder.vue'),
    component: () => import('../views/ski/ticket/submitOrder.vue'),
    meta: {
      auth: false,
      title: '提交订单',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/person',
    name: 'person',
    component: () => import('../views/home/person/person.vue'),
    meta: {
      auth: false,
      title: '出行人管理',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/deduce',
    name: 'deduce',
    component: () => import('../views/home/deduce/deduce.vue'),
    meta: {
      auth: false,
      title: '演艺赛事',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/deduceDetails/:id',
    name: 'deduceDetails',
    component: () => import('../views/home/deduce/deduceDetails/deduceDetails.vue'),
    meta: {
      auth: false,
      title: '演艺赛事-详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/culture',
    name: 'culture',
    component: () => import('../views/home/culture/culture.vue'),
    meta: {
      auth: false,
      title: '乐山文化',
      keepAlive: true,
      routeState: true,
    }
  },
  {
    path: '/cultureDetails',
    name: 'cultureDetails',
    component: () => import('../views/home/culture/cultureDetails/cultureDetails.vue'),
    meta: {
      auth: false,
      title: '乐山文化-详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/activ',
    name: 'activ',
    component: () => import('../views/home/activ/activ.vue'),
    meta: {
      auth: false,
      title: '特色活动',
      keepAlive: true,
      routeState: true,
    }
  },
  {
    path: '/activDetails/:id',
    name: 'activDetails',
    component: () => import('../views/home/activ/activDetails/activDetails.vue'),
    meta: {
      auth: false,
      title: '活动详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/submitOrderSnow',
    name: 'submitOrderSnow',
    component: () => import('../views/home/order/submitOrderSnow.vue'),
    meta: {
      auth: false,
      title: '提交订单',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/payWay',
    name: 'payWay',
    // component: () => import('../views/home/payWay/payWay.vue'),
    component: () => import('../views/ski/ticket/payWay/payWay.vue'),
    meta: {
      auth: false,
      title: '支付方式',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/paySuccess',
    name: 'paySuccess',
    component: () => import('../views/home/payWay/paySuccess.vue'),
    meta: {
      auth: false,
      title: '支付成功',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/orderList',
    name: 'orderList',
    component: () => import('../views/home/order/orderList.vue'),
    meta: {
      auth: false,
      title: '订单列表',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    // component: () => import('../views/home/order/orderDetail.vue'),
    component: () => import('../views/home/order/orderDetail20221122.vue'),
    meta: {
      auth: false,
      title: '订单详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/orderDetailSnow',
    name: 'orderDetailSnow',
    component: () => import('../views/home/order/orderDetailSnow.vue'),
    meta: {
      auth: false,
      title: '订单详情',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/homeBanner/:id',
    name: 'homeBanner',
    component: () => import('../views/home/activ/homeBanner/homeBanner.vue'),
    meta: {
      auth: false,
      title: '信息发布',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/commentList',
    name: 'commentList',
    component: () => import('../views/home/commentList/commentList.vue'),
    meta: {
      auth: false,
      title: '用户评价',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/hotelCommentList',
    name: 'hotelCommentList',
    component: () => import('../views/hotel/hotelCommentList.vue'),
    meta: {
      auth: false,
      title: '酒店评价',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/outWindow',
    name: 'outWindow',
    component: () => import('../views/outWindow.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/outWindowBlank',
    name: 'outWindowBlank',
    component: () => import('../views/outWindowBlank.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/outWeb',
    name: 'outWeb',
    component: () => import('../views/home/tickets/tiketDetails/outWeb.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/jfOutWindow',
    name: 'jfOutWindow',
    component: () => import('../views/jfOutWindow.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    }
  },
  {
    path: '/combo',
    name: 'combo',
    component: () => import('../views/combo/Combo.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    },
  },
  {
    path: '/comboDetail',
    name: 'comboDetail',
    component: () => import('../views/combo/ComboDetail.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    },
  },
  {
    path: '/lineJp',
    name: 'lineJp',
    component: () => import('../views/combo/lineJp.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    },
  },
  {
    path: '/comOrderDetail',
    name: 'comOrderDetail',
    component: () => import('../views/combo/comOrderDetail.vue'),
    meta: {
      auth: false,
      title: '',
      keepAlive: false,
      routeState: false
    },
  },
  {
    path: '/hotList',
    name: 'hotList',
    component: () => import('../views/home/hotList/hotList.vue'),
    meta: {
      auth: false,
      title: '更多酒店/民俗',
      keepAlive: false,
      routeState: false
    }
  },  // 推荐商品列表
  {
    path: '/hotelMore',
    name: 'hotelMore',
    component: () => import('../views/hotel/hotelMore.vue'), // 更多酒店
    meta: {
      auth: false,
      title: '更多酒店',
      keepAlive: false,
      routeState: false
    },
  },
  // 冰雪节千万消费劵派送
  //首页页面
  {
    path: '/skiHome',
    name: 'skiHome',
    component: () => import('../views/ski/skiHome/skiHome.vue'),
    meta: {
      auth: false,
      title: '首页',
      keepAlive: false,
      routeState: false
    },
  },
  //首页页面
  {
    path: '/skiHome',
    name: 'skiHome',
    component: () => import('../views/ski/skiHome/skiHome.vue'),
    meta: {
      auth: false,
      title: '首页',
      keepAlive: false,
      routeState: false
    },
  },
  //商品详情页面
  {
    path: '/goodsInfo',
    name: 'goodsInfo',
    component: () => import('../views/ski/goodsInfo/goodsInfo.vue'),
    meta: {
      auth: false,
      title: '详情页面',
      keepAlive: false,
      routeState: false
    },
  },
  //订单页面
  {
    path: '/goodsOrder',
    name: 'goodsOrder',
    component: () => import('../views/ski/goodsOrder/goodsOrder.vue'),
    meta: {
      auth: false,
      title: '填写订单',
      keepAlive: false,
      routeState: false
    },
  },
  //支付页面
  {
    path: '/goodsPay',
    name: 'goodsPay',
    component: () => import('../views/ski/goodsPay/goodsPay.vue'),
    meta: {
      auth: false,
      title: '选择支付方式',
      keepAlive: false,
      routeState: false
    },
  }, //零售订单支付成功页面
  {
    path: '/paySuccessRetail',
    name: 'paySuccessRetail',
    component: () => import('../views/ski/goodsPay/paySuccessRetail.vue'),
    meta: {
      auth: false,
      title: '订单详情',
      keepAlive: false,
      routeState: false
    },
  },
  //零售订单详情页面
  {
    path: '/retailOrderDetail',
    name: 'retailOrderDetail',
    component: () => import('../views/ski/retailOrderDetail/retailOrderDetail.vue'),
    meta: {
      auth: false,
      title: '订单详情',
      keepAlive: false,
      routeState: false
    },
  },//登陆页面页面
  {
    path: '/skiPhone',
    name: 'skiPhone',
    component: () => import('../views/ski/skiHome/skiPhone.vue'),
    meta: {
      auth: false,
      title: '登陆',
      keepAlive: false,
      routeState: false
    },
  },//消费劵列表页面
  {
    path: '/skiCouponList',
    name: 'skiCouponList',
    component: () => import('../views/ski/skiCouponList/skiCouponList.vue'),
    meta: {
      auth: false,
      title: '消费劵列表',
      keepAlive: false,
      routeState: false
    },
  },
  //评价页面
  {
    path: '/skiEvaluate',
    name: 'skiEvaluate',
    component: () => import('../views/ski/skiEvaluate/skiEvaluate.vue'),
    meta: {
      auth: false,
      title: '评价',
      keepAlive: false,
      routeState: false
    },
  },
  //优惠券商品列表
  {
    path: '/couponListGoods',
    name: 'couponListGoods',
    component: () => import('../views/ski/couponListGoods/couponListGoods.vue'),
    meta: {
      auth: false,
      title: '100元消费劵相关商品',
      keepAlive: false,
      routeState: false
    },
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  mode: 'history',
  routes
})


//  sessionStorage.setItem('LOGIN_STATUS', 2) //测试打包的时候需要注释掉
//
function getQueryVariable (variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) { return pair[1]; }
  }
  return (false);
}
/**添加登录记录source */
function addSouce(name){
 if (!sessionStorage.getItem('source')) {
  let str = window.location.href.split('source=')[1];
  if (str) {
    let source = str.split('#')[0];//  1峨眉山票务系统 2智游乐山
    let url = window.location.href.split('?')[0] + '#/'+ name;
    post({
      url: '/addSourceRecord',
      params: {
        "source": source, "url": url
      }
    }).then(rsp => {
      console.log(rsp)
    })
    /*api.addSourceRecord({ "source": source, "url": url }).then(res => {
      console.log(res)
    });*/
    sessionStorage.setItem('source', source);
  }
}
}

router.beforeEach((to, from, next) => { //beforeEach是router的钩子函数，在进入路由前执行
  console.log(to)
  next()
  /*if (to.name != 'skiPhone') {
    post({
      url: '/userInfo/findUserInfo',
      params: {
        token: global.token,
        deviceId: ''
      }
    }).then(rsp => {
      if (rsp.data.phoneNumber) {
        next()
      } else {
        router.push('/skiPhone')
      }
    })
  } else {
    next()
  }*/

})
export default router
