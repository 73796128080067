import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'amfe-flexible'
import './assets/style/common.scss'
import './assets/style/cover.scss'
import axios from 'axios'; // 引入axios//vant组件
import touch from 'vue-directive-touch';
// import Vant from 'vant';
import global from './util/global.js'; //
import bridge from './util/bridge'
import { Loading } from 'vant';
import 'vant/lib/index.css';
import {
  get,
  get2,
  get3,
  post,
  post2
} from './util/axios'; // 引入封装的网络请求

import Clipboard from 'clipboard';

import SHComment from './components/comment/src/comment.vue';
import shcallComment from './components/callComment/src/comment.vue';
import getShareInfo from './util/weixinApi.js'

// import VConsole from 'vconsole';
// const vconsole = new VConsole()


function renderTime(t) {
  var u = navigator.userAgent;
  var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 || u.indexOf("Linux") > -1;   //android终端
  var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || u.indexOf("iPhone") > -1 || u.indexOf("iOS") > -1 || u.indexOf('iPad') > -1 || (/Safari/.test(u) && !/Chrome/.test(u)); //ios终端
  if(isiOS){
    return t.replace(/\-/g,'/')
  }else{
    return t;
  }
}

// 对Date的扩展，将 Date 转化为指定格式的String
// 月(M)、日(d)、小时(H)、分(m)、秒(s)、季度(q) 可以用 1-2 个占位符，
// 年(y)可以用 1-4 个占位符，毫秒(S)只能用 1 个占位符(是 1-3 位的数字)
// 例子：
// (new Date()).Format("yyyy-MM-dd HH:mm:ss.S") ==> 2006-07-02 08:09:04.423
// (new Date()).Format("yyyy-M-d H:m:s.S")      ==> 2006-7-2 8:9:4.18
function formatDate(date, fmt) { //author: meizz
  var o = {
    "M+" : date.getMonth()+1,                 //月份
    "d+" : date.getDate(),                    //日
    "h+" : date.getHours(),                   //小时
    "m+" : date.getMinutes(),                 //分
    "s+" : date.getSeconds(),                 //秒
    "q+" : Math.floor((date.getMonth()+3)/3), //季度
    "S"  : date.getMilliseconds()             //毫秒
  };
  if(/(y+)/.test(fmt))
    fmt=fmt.replace(RegExp.$1, (date.getFullYear()+"").substr(4 - RegExp.$1.length));
  for(var k in o)
    if(new RegExp("("+ k +")").test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
  return fmt;
}


// number转大写
function toChinese(values,type){
  let chin_list=['零','一','二','三','四','五','六','七','八','九','十'];//所有的数值对应的汉字
  if(type === 'week'){
    chin_list[0] = '日'
    chin_list[7] = '日'
  }
  let sn = parseInt(values);
  return chin_list[sn];
}

// 计算日期区间
function getDaysBetween(dateString1,dateString2){
  var  startDate = isNaN(dateString1) ? Date.parse(dateString1) : dateString1;
  var  endDate = isNaN(dateString2) ? Date.parse(dateString2) : dateString2;
  var days=(endDate - startDate)/(1*24*60*60*1000);
  // alert(days);
  return  parseInt(days);
}


function setupWebViewJavascriptBridge(callback) { //ios联通h5的方法
  if (window.WebViewJavascriptBridge) { return callback(WebViewJavascriptBridge); }
  if (window.WVJBCallbacks) { return window.WVJBCallbacks.push(callback); }
  window.WVJBCallbacks = [callback];
  var WVJBIframe = document.createElement('iframe');
  WVJBIframe.style.display = 'none';
  WVJBIframe.src = 'https://__bridge_loaded__';
  document.documentElement.appendChild(WVJBIframe);
  setTimeout(function() { document.documentElement.removeChild(WVJBIframe) }, 0)
}

const app = createApp(App).use(store).use(router).use(touch).use(Loading);

app.component('SHComment',SHComment);
app.component('shcallComment',shcallComment);

app.config.globalProperties.$global = global; // 全局定义axios的get方法
app.config.globalProperties.$get = get; // 全局定义axios的get方法
app.config.globalProperties.$get2 = get2; // 全局定义axios的get方法
app.config.globalProperties.$get3 = get3; // 全局定义axios的get方法下需要tooken
app.config.globalProperties.$post = post; // 全局定义axios的post方法
app.config.globalProperties.$post2 = post2; // 全局定义axios的post方法
app.config.globalProperties.$axios = axios; // 全局定义axios
app.config.globalProperties.$renderTime = renderTime; // 全局定义转换时间戳
app.config.globalProperties.$formatDate = formatDate; // 全局定义转换时间戳
app.config.globalProperties.$toChinese = toChinese; // 全局定义数字转大写
app.config.globalProperties.$getDaysBetween = getDaysBetween; // 全局定义数字转大写
app.config.globalProperties.$Clipboard = Clipboard; // 全局定义数字转大写
app.config.globalProperties.$iosBridge= bridge; // 全局定义ios交互方法
app.config.globalProperties.$iosMethod = setupWebViewJavascriptBridge; // 全局定义ios调用方法
app.config.globalProperties.getShareInfo = getShareInfo; 



app.config.ignoredElements = ['wx-open-launch-weapp']

app.mount('#app')



