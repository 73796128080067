import axios from 'axios';
const global = require('./global');
import { Toast } from 'vant';

const service = axios.create({
  // process.env.NODE_ENV === 'development' 来判断是否开发环境
  baseURL: process.env.NODE_ENV === 'development' ? global.baseUrl : global.baseUrl,
  timeout: 60000000
});

service.interceptors.request.use( config => {
  const token = global.token;
  if(token){
    config.headers = token ? {'token': global.token}:'';
  }
  if (config.ContentType) {
    config.headers['Content-Type'] = config.ContentType;
    delete config.ContentType
  }
  return config;
}, error => {
  return Promise.reject();
});

service.interceptors.response.use(response => {
  if(response.status === 200){
    if(response.data.status == 1 || response.data.code == 200 || response.data.message == '查询成功'){
      return response.data;
    }else{
      //针对天气接口写的判断
      if(response.data.queryName != '未知区域' || response.data.message != '查询失败'){
        Toast(response.data.message);
      }
      return  Promise.reject();
    }
  }else{
    return Promise.reject();
  }
}, error => {

  Toast('无请求参数');
  return Promise.reject(error);
});

export default service;
